/*jslint browser: true, nomen: true */
/*global $ */
'use strict';

$(function(){
	'use strict';

	$(".card.wrap-this").wrapAll("<div class='card-deck'></div>");

	// (new StickyFooter($('#wrap'), $('#footer'))).update().bindOnResize();
	(new ExternalLinkHandler).addTargetAttribute($('body'));
	(new UIBindings).bindEvents();
	(new UIBindings).bindTooltips();
	(new UIBindings).bindPopovers();
	(new UIBindings).bindSharing();
	(new UIBindings).bindSliders();
	(new UIBindings).bindThinkingSchoolsPopup();
	(new SocialFeed).socialstream();
	(new UIBindings).bindMagnificpopup();
	(new PageTimer).logToConsole();
	
	
	$('.affix').affix({
	  offset: {
	    top: 185,
	    bottom: function () {
	      return (this.bottom = $('#footer').outerHeight(true))
	    }
	  }
	})

	$('[data-affix]').customAffix();

});

$.fn.exists = function () {
	return $(this).length > 0;
};

$.fn.customAffix = function (options) {
    var that = $(this);
    if (!that.length) {
        return;
    }
    var settings = $.extend({
        scrollTopMinSelector: that.parent(),
        scrollTopMaxSelector: '#footer',
        offset: 20
    }, options),
    _window = $(window),
    positionElement = function () {

        var element, parentElement,
        scrollTopMaxElement,
        scrollTopMinElement,
        scrollTop,
        elementHeight,
        elementTop,
        documentHeight, scrollTopMax, scrollBottomMax, scrollDifference, top;

        element = $(this);
        parentElement = that.parent();
        scrollTopMaxElement = $(settings.scrollTopMaxSelector);
        scrollTopMinElement = $(settings.scrollTopMinSelector);
        scrollTop = _window.scrollTop();
        elementHeight = element.outerHeight();
        elementTop = scrollTopMinElement.offset().top; // + element.prevAll().outerHeight();
        documentHeight = $(document).outerHeight();
        scrollTopMax = scrollTopMaxElement.outerHeight();
        scrollBottomMax = documentHeight - ( elementHeight + scrollTopMax);
        scrollDifference = scrollBottomMax - scrollTop;

        element.removeClass('sticky static');

        if (scrollTop < elementTop - settings.offset) {
            element.removeClass('sticky').addClass('static');
        } else if (scrollTop < scrollBottomMax) {
            element.addClass('sticky').css({'top': settings.offset, 'width': parentElement.width()});
        } else {
            element.addClass('sticky').css({'top': scrollDifference, 'width': parentElement.width()});
        }
    };

    return this.each(function () {
        _window.on('scroll scrollstop resize', $.proxy(positionElement, this));
        $.proxy(positionElement, this)();
    });
};

function PageTimer() {
	var self = this;
	this.getLoadTime = function () {
		var now = new Date().getTime();
	    // Get the performance object
	    window.performance = window.performance || window.mozPerformance || window.msPerformance || window.webkitPerformance || {};
	    var timing = performance.timing || {};

	    var ms = now - timing.navigationStart;

	    return Math.round(ms / 10)/100;
	};

	this.logToConsole = function () {
		$(window).on('load', function () {
			console && console.info && console.info("Client loaded in " + self.getLoadTime() + 's');
		});
	};

	this.append = function (holder) {
		$(window).on('load', function () {
			holder.text(' | LT: ' + self.getLoadTime() + 's');
		});
	}
}

function StickyFooter ($wrap, $footer) {
	var $window = $(window);
	this.updateWrapperCSS = function () {
		var footerHeight = $footer.outerHeight();

		$wrap.css({
			marginBottom: -1 * footerHeight,
			paddingBottom: footerHeight
		});
	};

	this.bindOnResize = function () {
		$window.on('resize', this.updateWrapperCSS);

		return this;
	};

	this.update = function () {
		this.updateWrapperCSS();

		return this;
	}
}

function SocialFeed(){
	this.socialstream = function(){

		var socialStreamHome = $('#social-stream');
		
		if(socialStreamHome.exists()) {
			socialStreamHome.dcSocialStream({
				feeds: {

					twitter: {
						id: '#stpeterscollegeza,#stpetersza,stpeterscoll',
						out: 'intro,text,user,share',
						url: '/lib/twitter.php',
						icon:'<i class="fa fa-facebook"></i>',
						thumb: true
					},
					facebook: {
						id: '284817088363091,328448760641946,617600281672744',
					  	comments: 0,
						image_width: 3, //3 = 600 4 = 480 5 = 320 6 = 180,
						url: '/lib/facebook.php',
						out: 'intro,thumb,title,text',
						icon: '<i class="fa fa-twitter"></i>'
					},
					instagram: {
						id: '!1660223162,#stpetersza,#stpeterscollegeza',
						accessToken: '49507621.3197133.448a74da35a143d5942497a6a5ff7a72',
						redirectUrl: 'http://www.stpeters.co.za/college/social-wall',
						clientId: '3197133f79a54035866c93b82398ad3a',
						comments: 3,
						likes: 5,
						icon: '<i class="fa fa-instagram"></i>',
						out: 'intro,thumb,text'
					},
				},
				rotate: {
					delay: 0
				},

				style: {
				  layout: 'modern',
				  colour: 'light'
				},
				control: false,
				filter: true,
				wall: true,
				// order: 'random',
				days: 60,
				limit: 20,
				iconPath: '/images/dcsns-dark/',
				imagePath: '/images/dcsns-dark/',
				debug: false
			});

		}
	}

}

function ExternalLinkHandler () {
	var self = this;
	var hostname = document.location.hostname;

	this.matchInternalLink = [new RegExp("^https?:\/\/(.*?)" + hostname)];

	this.addTargetAttribute = function (context) {
		context.find('a').filter('[href^="http://"], [href^="https://"]').each(function () {
			var anchor = $(this);
			var href = anchor.attr('href');
			var isInternalLink = false;

			for (var i = 0; i < self.matchInternalLink.length; i++) {
				var regex = self.matchInternalLink[i];
				if (href.match(regex)) {
					isInternalLink = true;
				}
			}

			if (!isInternalLink) {
				anchor.attr('target', '_blank').addClass('external-link');
			}
		});
	};
}

function UIBindings () {
	this.bindTooltips = function () {
		$('[data-toggle="tooltip"]').tooltip();
	};

	this.bindPopovers = function () {
		$('[data-toggle="popover"]').popover();
	};

	this.bindSharing = function () {
		$("[data-share-default]").each(function () {
		  var shareHandler = new ShareHandler($(this));
		  shareHandler.appendFacebook();
		  shareHandler.appendTwitter();
		  shareHandler.appendGoogle();
		  //shareHandler.appendLinkedIn();
		  shareHandler.appendPinterest();
		});
	};

	this.bindMagnificpopup = function(){
		$('.lightbox').magnificPopup({type:'image'});
		// allow for relative video popup
		$('.video-popup').magnificPopup({type:'iframe'});
	};

	this.bindSliders = function(){
		
		$('.offerings-slider').slick({
			infinite: true,
			speed: 300,
			slidesToShow: 5,
			slidesToScroll: 5,
			responsive: [
			  {
			    breakpoint: 1024,
			    settings: {
			      slidesToShow: 4,
			      slidesToScroll: 4,
			      infinite: true,
			      dots: true
			    }
			  },
			  {
			    breakpoint: 600,
			    settings: {
			      slidesToShow: 3,
			      slidesToScroll: 3
			    }
			  },
			  {
			    breakpoint: 480,
			    settings: {
			      slidesToShow: 2,
			      slidesToScroll: 2
			    }
			  }
			 ]
		});


		$('.banner-carousel-inner').slick({
			dots: true,
			infinite: true,
			speed: 500,
			fade: true,
			cssEase: 'linear',
			autoplay: true,
	  		autoplaySpeed: 6000,
			responsive: [
			  {
			    breakpoint: 992,
			    settings: {
			      autoplay: false,
	  			  autoplaySpeed: 0
			    }
			  }
			]
			
		});

		$('.banner-slider').slick({
			dots: false,
			infinite: true,
			arrows: false,
			speed: 500,
			fade: true,
			cssEase: 'linear',
			autoplay: true,
	  		autoplaySpeed: 3000
			
		});

		$('.magazine-slider').slick({
			dots: false,
			infinite: true,
			arrows: true,
			prevArrow:"<i class='fa fa-angle-left fa-2x text-blue' aria-hidden='true'></i>",
            nextArrow:"<i class='fa fa-angle-right fa-2x text-blue' aria-hidden='true'></i>",
			speed: 200,
			fade: false,
			cssEase: 'linear',
			autoplay: false
		});


		$('.peek-slider-01, .peek-slider-02, .peek-slider-03, .peek-slider-04').slick({
			dots: false,
			infinite: true,
			arrows: false,
			speed: 500,
			fade: true,
			cssEase: 'linear',
			autoplay: true,
	  		autoplaySpeed: 3000,
			pauseOnHover: false
		});


		$('.testimonial-slide').slick({
			dots: true,
			infinite: true,
			speed: 500,
			fade: true,
			cssEase: 'linear',
			autoplay: true,
	  		autoplaySpeed: 6000,
	  		arrows: false,
	  		prevArrow:"<i class='fa fa-angle-left' aria-hidden='true'></i>",
            nextArrow:"<i class='fa fa-angle-right' aria-hidden='true'></i>",
			responsive: [
			  {
			    breakpoint: 992,
			    settings: {
			      autoplay: false,
	  			  autoplaySpeed: 0
			    }
			  }
			]
			
		});


		$('.slider-for').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			fade: true,
			asNavFor: '.slider-nav'
		});

		$('.slider-nav').slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			asNavFor: '.slider-for',
			centerMode: true,
			focusOnSelect: true
		});
	};

	this.bindThinkingSchoolsPopup = function() {
	    var visit = $.cookie("STPETERSFIRSTTIME");
		
		console.log(visit);

	    if (!visit) {
	    	$('.newsletterModal').modal('show');
	    	$.cookie("STPETERSFIRSTTIME", true, { expires : 365 });
	    }
	}


	this.bindEvents = function() {

		var uniteGallery = $("#gallery");

		if (uniteGallery.exists()) {
			uniteGallery.unitegallery({
				gallery_theme: "grid",
				theme_panel_position: "bottom",
				slider_scale_mode: "fit"
			});
		}

		$('a[href$=".pdf"]').prop('target', '_blank');

		// --------------------------
		// ALERT CHECK
		// --------------------------

		// Show all DOM elements that have data-show-once
		// cookie_key is set in DOM


		var ck = $("[data-cookiekey]").data("cookiekey");

		if (ck && !$.cookie(ck)) {
			$("#alert-pane").addClass("in");
		}

		$("a[data-toggle='collapse']").on("click", function() {
			$.cookie(ck, true);
		});

		// donate

	    $('.donate').on('click', function() {
			var valid = true;
			$('[required]').each(function() {
				if ($(this).is(':invalid') || !$(this).val()) valid = false;
			})
			if (!valid) {
				alert('Please ensure all details are entered into the donations form');
				return false;
			}

			console.log(valid);
			// else alert('valid');

	        var action = "/?ACT=" + $('#donate [name="process_act"]').val();

	        $.ajax({
	            type: 'POST',
	            url: action,
	            data: $('#donate').serialize(),
	            dataType: 'json',
	            beforeSend: function() {
	                $(this).attr('disabled', true);
					
	            },
	            success: function(json) {

	                if (json['errors']) {
	                    if(json['errors']['amount']) {
	                        $('#donate #inputAmount').css('border', "1px solid red");
	                    }
	                } else {

	                    if(json['redirect']) {
	                        window.location = json['redirect']; return false;
	                    }

	                    $('#donate [name="p1"]').val(json['vcs_id']);
	                    $('#donate [name="p2"]').val(json['reference']);
	                    $('#donate [name="p3"]').val(json['description']);
	                    $('#donate [name="p4"]').val(json['amount']);
	                    $('#donate [name="p10"]').val(json['return_url']);
	                    $('#donate [name="ApprovedUrl"]').val(json['return_url']);
	                    $('#donate [name="DeclinedUrl"]').val(json['return_url']);
	                    $('#donate [name="CardholderName"]').val(json['fullname']);
	                    $('#donate [name="hash"]').val(json['checksum']);
	                    $('#donate').attr('action', json['vcs_url']);
	                    $('#donate').submit();
	                }



	                $('#donate .donate-submit').attr('disabled', false);


	                $('.attention').remove();
	            }
	        });

	        return false;
	    });
		
	}
}